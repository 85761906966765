<template>
  <div>
    <b-card>
      <b-tabs
        ref="notificationTabBar"
      >
        <b-tab>
          <template #title>
            <span>Other Alerts</span>
          </template>
          <OtherAlerts />
        </b-tab>
        <b-tab>
          <template #title>
            <span>Banner Alerts</span>
          </template>
          <BannerNotificationForm />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue'
import OtherAlerts from '@/components/Alerts/OtherAlers.vue'
import BannerNotificationForm from '@/components/Alerts/BannerNotificationForm.vue'
// eslint-disable-next-line import/extensions

export default {
  components: {
    OtherAlerts,
    BannerNotificationForm,
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {
      roles: [],
      colors: [
        { name: 'Red', color: '#B93160' },
        { name: 'Yellow', color: '#E7AB79' },
        { name: 'Green', color: '#5FD068' },
        { name: 'Blue', color: '#4688f5' },
      ],
      form: {
        is_staff_active: false,
        is_merchant_active: false,
        is_sms_active: false,
        is_email_active: false,
        is_push_notification_active: false,
        is_banner_alert_active: false,
        notification_body: '',
        subject: '',
        banner_data: {
          expiray_date: '2022-08-03 12:35',
          recuring_enabled: true,
          recuring_value: '2',
          color: '',
        },
        user_roles: {
          'staff-api': [],
        },
        tenant_id: null,
      },
      flatPickrConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altInput: true,
        dateFormat: 'Y-m-d H:i',
      },
      tenants: [],
      loading: false,
    }
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('notificationTabBar', 'notificationTabIndex')
  },
}
</script>
