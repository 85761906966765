<template>
  <div>
    <b-card>
      <b-overlay
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-form @submit.prevent="handleSubmit()">
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                <b-form-group
                  label="Select Tenants"
                  label-for="tenant"
                  class="w-100 "
                >
                  <v-select
                    v-model="form.tenant_id"
                    label="name"
                    :reduce="(option) => option.id"
                    deselect-from-dropdown
                    placeholder="Select Tenant"
                    :options="tenants"
                    @input="getRoleListForDropdown"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                    <template #search="{attributes, events}">
                      <input
                        class="vs__search"
                        style="pointer-events:none"
                        :required="!form.tenant_id"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                </b-form-group>
                <!-- <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="form.tenant_ids.length === 0 ? 'primary' : 'danger'"
                    class="width-200 h-100 mt-2"
                    @click="form.tenant_ids.length === 0 ? onClickSelectAllTenants() : onClickDeselectAllTenants()"
                  >
                    {{ form.tenant_ids.length === 0 ? 'Select All' : 'Deselect All' }}
                  </b-button> -->
              </div>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Add Your Message here"
                label-for="h-message"
              >
                <b-form-textarea
                  id="h-message"
                  v-model="form.notification_body"
                  :state="form.notification_body.length <= 200"
                  placeholder="Message"
                  rows="3"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- email -->
            <b-col cols="12">
              <b-form-group
                label="Email Subject"
                label-for="d-subject"
              >
                <b-form-input
                  id="d-subject"
                  v-model="form.subject"
                  type="subject"
                  placeholder="Enter the subject to the email here"
                  autocomplete="nope"
                  :required="form.is_email_active"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <b-form-group
                  label="Notifiable Parties"
                  label-for="notifiable-parties"
                >
                  <div class="d-flex flex-wrap mt-1">
                    <b-form-checkbox
                      v-model="form.is_staff_active"
                      :value="true"
                      class="custom-control-secondary mr-2"
                    >
                      Staff
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="form.is_merchant_active"
                      :value="true"
                      class="custom-control-secondary mr-2"
                    >
                      Merchant
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-form-group>
            </b-col>

            <!--Select Applicable Staff Roles  -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Applicable Staff Roles"
              >
                <v-select
                  v-model="form.user_roles['staff-api']"
                  :options="roles"
                  :reduce="option => option.id"
                  label="name"
                  multiple
                  placeholder="Select Role"
                  :disabled="!form.is_staff_active"
                  deselect-from-dropdown
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      style="pointer-events:none"
                      :required="( form.user_roles ? form.user_roles['staff-api'].length === 0 : true ) && ( form.is_staff_active)"
                      v-bind="attributes"
                      v-on="events"
                    >
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <!-- Broadcast Type -->
            <b-col cols="12">
              <b-form-group>
                <b-form-group
                  label="Broadcast Type"
                  label-for="d-broadcast-type"
                >
                  <div class="d-flex flex-wrap mt-1">
                    <b-form-checkbox
                      v-model="form.is_push_notification_active"
                      :value="true"
                      class="custom-control-secondary mr-2 mb-1"
                    >
                      Send Portal Notification
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="form.is_sms_active"
                      :value="true"
                      class="custom-control-secondary mr-2"
                    >
                      Send SMS
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="form.is_email_active"
                      :value="true"
                      class="custom-control-secondary mr-2"
                    >
                      Send Email
                    </b-form-checkbox>
                    <!-- <b-form-checkbox
                      v-model="form.is_banner_alert_active"
                      :value="true"
                      class="custom-control-secondary"
                    >
                      Send Banner Notification
                    </b-form-checkbox> -->
                  </div>
                </b-form-group>
              </b-form-group>
            </b-col>
            <!-- Select Banner Color -->
            <!-- <b-col
              cols="12"
              md="4"
              :v-if="form.is_banner_alert_active"
            >
              <b-form-group
                label="Select Banner Color"
              >
                <v-select
                  v-model="form.banner_data.color"
                  :options="colors"
                  label="Color"
                  placeholder="Select Banner Color"
                  :disabled="!form.is_banner_alert_active"
                  :reduce="(option) => option.color"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col> -->

            <!--Select Expiray Date  -->
            <!-- <b-col
              cols="12"
              md="4"
              :v-if="form.is_banner_alert_active"
            >
              <b-form-group
                label="Select an Expiry Date"
              >
                <b-input-group>
                  <flat-pickr
                    v-model="form.banner_data.expiray_date"
                    :config="flatPickrConfig"
                    class="form-control"
                    placeholder="Select date"
                    name="date"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-default"
                      type="button"
                      title="Toggle"
                      data-toggle
                    >
                      <i class="fa fa-calendar">
                        <span
                          aria-hidden="true"
                          class="sr-only"
                        >Toggle</span>
                      </i>
                    </button>
                    <button
                      class="btn btn-default"
                      type="button"
                      title="Clear"
                      data-clear
                    >
                      <i class="fa fa-times">
                        <span
                          aria-hidden="true"
                          class="sr-only"
                        >Clear</span>
                      </i>
                    </button>
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col> -->
            <!-- submit and reset -->
            <b-col
              v-can="'create-merchant-notification'"
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="clearForm"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'

const NotificationRepository = RepositoryFactory.get('notification')
const ClientRepository = RepositoryFactory.get('client')
const RoleRepository = RepositoryFactory.get('role')

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    BOverlay,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      roles: [],
      colors: [
        { name: 'Red', color: '#B93160' },
        { name: 'Yellow', color: '#E7AB79' },
        { name: 'Green', color: '#5FD068' },
        { name: 'Blue', color: '#4688f5' },
      ],
      form: {
        is_staff_active: false,
        is_merchant_active: false,
        is_sms_active: false,
        is_email_active: false,
        is_push_notification_active: false,
        is_banner_alert_active: false,
        notification_body: '',
        subject: '',
        banner_data: {
          expiray_date: '2022-08-03 12:35',
          recuring_enabled: true,
          recuring_value: '2',
          color: '',
        },
        user_roles: {
          'staff-api': [],
        },
        tenant_id: null,
      },
      flatPickrConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altInput: true,
        dateFormat: 'Y-m-d H:i',
      },
      tenants: [],
      loading: false,
    }
  },
  mounted() {
    this.getClientListForDropdown()
  },
  methods: {
    async getClientListForDropdown() {
      this.loading = true
      try {
        const { data } = await ClientRepository.getClientListForDropdown()
        this.tenants = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getRoleListForDropdown() {
      this.loading = true
      try {
        const { data } = await RoleRepository.getStaffRoleListforDropdown(
          this.form.tenant_id,
        )
        this.roles = data.data
        this.roles.unshift({
          id: 'all',
          name: 'All',
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async handleSubmit() {
      try {
        this.loading = true
        const payload = {
          is_staff_active: this.form.is_staff_active,
          is_merchant_active: this.form.is_merchant_active,
          is_sms_active: this.form.is_sms_active,
          is_email_active: this.form.is_email_active,
          is_push_notification_active: this.form.is_push_notification_active,
          is_banner_alert_active: this.form.is_banner_alert_active,
          notification_body: this.form.notification_body,
          subject: this.form.subject,
          banner_data: this.form.banner_data,
          user_roles: JSON.parse(JSON.stringify(this.form.user_roles)),
          tenant_ids: [this.form.tenant_id],
        }
        if (
          payload.user_roles['staff-api'].filter(role => role === 'all')
            .length > 0
        ) {
          payload.user_roles = 'all'
        }
        const res = await NotificationRepository.sendNotification(payload)
        if (res.status === 200) {
          this.showSuccessMessage('Alert broadcasted successfully')
          this.clearForm()
        } else {
          this.showErrorMessage('Failed to send alert')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    // onClickSelectAllTenants() {
    //   this.form.tenant_ids = this.tenants.map(tenant => tenant.id)
    // },
    // onClickDeselectAllTenants() {
    //   this.form.tenant_ids = []
    // },
    clearForm() {
      this.form.is_staff_active = false
      this.form.is_merchant_active = false
      this.form.is_sms_active = false
      this.form.is_email_active = false
      this.form.is_push_notification_active = false
      this.form.is_banner_alert_active = false
      this.form.banner_data.expiray_date = ''
      this.form.banner_data.color = ''
      this.form.notification_body = ''
      this.form.subject = ''
      this.form.user_roles = {
        'staff-api': [],
      }
      this.form.tenant_id = null
    },
  },
}
</script>
  <style lang="scss">
  @import '../@core/scss/vue/libs/vue-select.scss';
  </style>
